export const WORDS = [
  'poopy',
  'passy',
  'babby',
  'sweet',
  'cutie',
  'roony',
  'yitzy',
  'pitzy',
  'yummy',
  'tummy',
  'baldy',
  'cries',
  'tears',
  'yells',
  'belly',
  'sleep',
  'awake',
  'spits',
  'aloud',
  'fussy',
  'nappy',
  'doona',
  'shots',
  'cough',
  'poops',
  'silly',
  'doody',
  'smush',
  'tushy',
  'nurse',
  'milky',
  'nosie',
  'mouth',
  'henty',
  'teeth',
  'footy',
  'shlof',
  'socks',
  'shirt',
  'onesy',
  'pluff',
  'hairy',
  'crazy',
  'mucus',
  'pupsy',
  'shosh',
  'hoody',
  'hudle',
  'yehud',
  'cloth',
  'wipes',
  'burps',
  'burpy',
  'wipey',
  'bathy',
  'babbo',
  'whine',
  'fluff',
  'grunt',
  'keppy',
  'achoo',
  'mushy',
  'brahm',
  'savta',
  'sabba',
  'zaidy',
  'bubby',
  'mommy',
  'daddy',
  'aunty',
  'uncle',
  'tanty',
  'cutie'
]
